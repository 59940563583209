import { injectable } from 'inversify-props';
import dayjs from 'dayjs';
import BaseService from '@/services/base-service';
import SaleByCollectionModel from '@/models/crm/sale-by-collection.model';

@injectable()
export default class SaleService extends BaseService {
  async getSalesByCollection(clientId: string): Promise<SaleByCollectionModel[]> {
    return (await this.httpService.get(
      SaleByCollectionModel,
      `${this.getApiPath()}/crm/venda/list-by-collection?cnpj=${clientId}`,
    )) as SaleByCollectionModel[];
  }

  static generateSalesByCollectionExportFilename(date: Date): string {
    return `Vendas_Colecao_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }
}
