import { Transform } from 'class-transformer';
import { AttendanceOriginEnum } from '@/enums/crm/attendance-origin.enum';
import { DateTransform } from '@/utils/value-transformers/date.transform';
import { TimeTransform } from '@/utils/value-transformers/time.transform';

export default class AttendanceModel {
  id!: number;

  index!: string;

  idRegistro!: number;

  idPrincipal!: number;

  idAtendente!: number;

  nomeAtendente!: string;

  @Transform(DateTransform)
  data!: Date;

  @Transform(TimeTransform)
  hora!: string;

  valor!: number;

  titulo!: string;

  descricao!: string;

  observacao!: string;

  origem!: AttendanceOriginEnum;

  tipoHistorico!: string;
}
