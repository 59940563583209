export default class SaleByCollectionModel {
  colecao!: string;

  codColecao!: string;

  qtdePedidos!: number;

  valorBruto!: number;

  valorLiquido!: number;

  valorDesconto!: number;

  percDesconto!: number;

  valorComissao!: number;

  percComissao!: number;

  precoMedio!: number;

  quantidade!: number;

  qtdeItens!: number;

  prazoMedio!: number;

  prazoMedioValor!: number;

  qtdeClientes!: number;

  vendaMedia!: number;

  vendaMediaPc!: number;

  qtdeTickets!: number;

  ticketMedio!: number;

  ticketMedioPc!: number;
}
