import { Type, Exclude } from 'class-transformer';
import UserModel from '@/models/user.model';
import ConversationDepartmentModel from '@/models/crm/conversation-department.model';
import ConversationMessageModel from '@/models/crm/conversation-message.model';
import ConversationLogModel from '@/models/crm/conversation-log.model';
import ClientModel from '@/models/crm/client.model';
import ProspectModel from '@/models/crm/prospect.model';
import { ConversationTypeEnum } from '@/enums/crm/conversation-type.enum';
import ContactModel from './contact.model';

export default class ConversationModel {
  id!: number;

  tipo!: ConversationTypeEnum;

  origemConversa!: string;

  templateConversa!: string;

  waConversationId!: number;

  numeroWhatsapp!: string;

  numeroContato!: string;

  nomeContato!: string;

  cnpj!: string;

  protocolo!: string;

  fechado!: number;

  bloqueado!: number;

  expirado!: number;

  @Type(() => Date)
  dataInicio!: Date;

  @Type(() => Date)
  dataFechamento!: Date;

  @Type(() => Date)
  dataUltimaMensagem!: Date;

  descricao!: string;

  engano!: number;

  outraJustificativaFechamento!: number;

  @Type(() => UserModel)
  atendente!: UserModel;

  @Type(() => ContactModel)
  contato!: ContactModel;

  @Type(() => ClientModel)
  cliente!: ClientModel;

  @Type(() => ProspectModel)
  prospect!: ProspectModel;

  @Type(() => ConversationDepartmentModel)
  departamento!: ConversationDepartmentModel;

  @Type(() => ConversationMessageModel)
  messages!: ConversationMessageModel[];

  @Exclude()
  private internalLogs: ConversationLogModel[] = [];

  get isOpen(): boolean {
    return this.atendente && !!this.atendente.id && !!this.dataInicio;
  }

  get isClosed(): boolean {
    return !!this.fechado;
  }

  get isNew(): boolean {
    return !this.id;
  }

  set logs(logs: ConversationLogModel[]) {
    this.internalLogs = logs;
  }

  get logs(): ConversationLogModel[] {
    return this.internalLogs;
  }
}
