import { Type } from 'class-transformer';

export default class ProspectCityClientsModel {
  cliente!: string;

  representante!: string;

  situacaoErp!: string;

  situacaoGestao!: string;

  endereco!: string;

  @Type(() => Date)
  dataUltimaCompra!: Date;

  valorUltimaCompra!: number;

  qtdePecasUltimaCompra!: number;

  qtdePedidos!: number;

  ticketMedioValor!: number;

  mediaPecas!: number;

  clienteAtivoMaisProximo!: string;

  distanciaClienteAtivoMaisProximo!: number;

  clienteAtivoPedidoMaisRecente!: string;

  distanciaClienteAtivoPedidoMaisRecente!: number;

  valorClienteAtivoPedidoMaisRecente!: number;
}
