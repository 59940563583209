import { injectable } from 'inversify-props';
import { ClientTypeEnum } from '../enums/client-type.enum';
import ClientModel from '@/models/crm/client.model';

@injectable()
export default class SessionService {
  private token!: string;

  private url!: string;

  private socketUrl!: string;

  private builtIn!: boolean;

  private appBar!: boolean;

  private clientTypeMode!: ClientTypeEnum;

  private client!: ClientModel;

  private order!: string;

  private admOrRoot = true;

  get isAdmOrRoot(): boolean {
    return this.admOrRoot;
  }

  set isAdmOrRoot(admOrRoot: boolean) {
    this.admOrRoot = admOrRoot;
  }

  get apiToken(): string {
    return this.token;
  }

  set apiToken(token: string) {
    this.token = token;
  }

  get orderCode(): string {
    return this.order;
  }

  set orderCode(orderCode: string) {
    this.order = orderCode;
  }

  get apiUrl(): string {
    return this.url;
  }

  set apiUrl(url: string) {
    this.url = url;
  }

  get socketIOUrl(): string {
    return this.socketUrl;
  }

  set socketIOUrl(socketUrl: string) {
    this.socketUrl = socketUrl;
  }

  get builtInMode(): boolean {
    return this.builtIn;
  }

  set builtInMode(builtInMode: boolean) {
    this.builtIn = builtInMode;
  }

  get hideAppBar(): boolean {
    return this.appBar;
  }

  set hideAppBar(hideAppBar: boolean) {
    this.appBar = hideAppBar;
  }

  get clientType(): ClientTypeEnum {
    return this.clientTypeMode || ClientTypeEnum.Client;
  }

  set clientType(clientType: ClientTypeEnum) {
    this.clientTypeMode = clientType;
  }

  get activeClient(): ClientModel {
    return this.client;
  }

  set activeClient(client: ClientModel) {
    this.client = client;
  }
}
