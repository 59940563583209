export default class SettingsModel {
  flagHabilitaNovoPainelCliente!: boolean;

  flagConverteProspectIGS!: boolean;

  flagIntegraClienteAposConversaoProspect!: boolean;

  flagUsaSegundaViaBoletos!: boolean;

  flagIntegracaoSintegraAtivo!: boolean;

  urlCrmApp!: string;

  flagObrigaClassificacaoFechamentoConversa!: boolean;

  flagObrigaDescricaoFechamentoConversa!: boolean;

  flagTornarCnpjProspectObrigatorio!: boolean;

  flagUtilizaWhatsapp!: boolean;

  flagUtilizaAgenda!: boolean;

  loggedUserType!: number;

  flagPermiteExcluirAnexos!: boolean;

  flagMostraChatsUsuariosRootVisaoGeral!: boolean;

  flagObrigaClassificacaoEmail!: boolean;

  flagHabilitaMenuVisitas!: boolean;

  flagPermiteEditarParticularidades!: boolean;

  flagHabilitaMenuClientesCidadeProspect!: boolean;

  flagCongelaPreenchimentoSintegra!: boolean;

  flagNaoPreencheTelefoneEmailProspectSintegra!: boolean;

  flagEmailProspectObrigatorio!: boolean;

  flagTelefoneProspectObrigatorio!: boolean;
}
