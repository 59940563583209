export enum InjectionIdEnum {
  SessionService = 'SessionService',
  HttpService = 'HttpService',
  RouterService = 'RouterService',
  LocalizationService = 'LocalizationService',
  SintegraService = 'SintegraService',
  AttendantService = 'AttendantService',

  ConfiguratorLayoutEmailService = 'ConfiguratorLayoutEmailService',
  ConfiguratorVariableTextService = 'ConfiguratorVariableTextService',
  ConfiguratorVariableColumnService = 'ConfiguratorVariableColumnService',

  CrMetasUploadService = 'CrMetasUploadService',
  CrMetasService = 'CrMetasService',
  CrMetasPeriodosService = 'CrMetasPeriodosService',
  RepresentativeClientService = 'RepresentativeClientService',

  CrmSettingsService = 'CrmSettingsService',
  CrmClientService = 'CrmClientService',
  CrmOrderService = 'CrmOrderService',
  CrmContactService = 'CrmContactService',
  CrmFinancialInformationService = 'CrmFinancialInformationService',
  CrmProcessService = 'CrmProcessService',
  CrmActivityService = 'CrmActivityService',
  CrmRepresentativeService = 'CrmRepresentativeService',
  CrmUploadService = 'CrmUploadService',
  CrmVisitMadeService = 'CrmVisitMadeService',
  CrmSaleService = 'CrmSaleService',
  CrmClientManagementService = 'CrmClientManagementService',
  CrmAttachmentService = 'CrmAttachmentService',
  CrmEmailService = 'CrmEmailService',
  CrmAttendanceService = 'CrmAttendanceService',
  CrmConversationService = 'CrmConversationService',
  CrmProspectService = 'CrmProspectService',
  CrmLeadService = 'CrmLeadService',
  CrmContactChannelService = 'CrmContactChannelService',
  CrmServiceQueueService = 'CrmServiceQueueService',
  CrmStatisticsService = 'CrmStatisticsService',

  GCClassificationService = 'GCClassificationService',
  GCCollectionService = 'GCCollectionService',
  GCGeneralConfigService = 'GCGeneralConfigService',

  B2OrderAuditService = 'B2OrderAuditService',
}
